<script lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { reactiveComputed, useIntervalFn } from '@vueuse/core'
import { actions } from 'xstate'
import { useMachine } from '@xstate/vue'
import { machine as machineBatchRenting } from './machineBatchRenting'
import { useRentDuration } from '~/composables/tron'
import { useRentalTime } from '~/composables/useRentalTime'
import MoreDuration from '~/components/more-duration/index'
import useHideFormHelp from '~/composables/useHideFormHelp'
import { useUserInfo } from '~/composables/app'
import { tron } from '~/utils/tron-v2'
import { resourcePriceRange, useCalculation } from '~/composables/app/getB2cAmountCalculator'
import { useAvailableEnergy } from '~/composables/app/useAvailableEnergy'
import BigNumber, { default as bignumber } from 'bignumber.js';
</script>

<!-- todo rentTimeSecond 这个字段已经多余了，后期要删的 -->
<script setup lang="ts">
const { rentableData, rentableColumns } = useAvailableEnergy()
const { hasLogged, userInfo, userInfoQuerier, isLoading } = useUserInfo()
const unitPriceRange = resourcePriceRange(toRef(false))
/**  API 密钥字段 */
function useSecretKey() {
  const key = ref('')
  const enabled = computed(() => {
    return unref(key).length === 36
  })
  const apiQuerier = useQuery({
    queryKey: ['balance', key],
    staleTime: 1 * 60 * 1000,
    enabled: unref(enabled),
    queryFn: async () => {
      const res = await apisOpen.apiQuery(undefined, {
        headers: {
          key: toValue(key),
        },
      })

      return res ?? null
    },
    retry(failureCount, error) {
      if (error instanceof ApiError && error.code === 20000)
        return false

      return failureCount < 3
    },
  })
  const balance = computed(() => apiQuerier.data.value?.trx_money ?? null)
  const balanceLoading = computed(() => apiQuerier.isFetching.value)
  const refetchBalance = () => {
    if (toValue(key)?.length === 36)
      apiQuerier.refetch()
  }

  const emptyKey = () => {
    key.value = ''
  }
  return reactive({ key, balance, balanceLoading, refetchBalance, emptyKey })
}

/**  租用资源数量字段 */
function useRentResValue() {
  const { config, usableEnergy, usableEnergyQuerier } = useAppState()
  const value = ref()
  const minValue = computed(() => config?.value?.order?.energy_min_value ?? 0)
  const platformRes = computed(() => unref(usableEnergy)?.usable_energy)
  const platformResLoading = computed(
    () => usableEnergyQuerier.isFetching.value,
  )
  const platformResRefetch = () => usableEnergyQuerier.refetch()

  return reactive({
    value,
    minValue,
    platformRes,
    platformResLoading,
    platformResRefetch,
  })
}

/**  接收地址字段 */
function useReceiveAddress() {
  const MAX_COUNT = 1000
  const addresses = ref('')
  const addressList = computed(() => {
    return unref(addresses)
      .trim()
      .split('\n')
      .map(item => item.trim().match(/\w+$/)?.[0] ?? '')
      .filter(item => !!item)
  })
  const count = computed(() => unref(addressList).length)

  // 限制超过 MAX_COUNT
  watch(addressList, (list) => {
    if (list.length > MAX_COUNT) {
      nextTick(() => {
        addresses.value = unref(addressList).splice(0, MAX_COUNT).join('\n')
      })
    }
  })

  return reactive({
    value: addresses,
    addressList,
    count,
    maxCount: MAX_COUNT,
  })
}

/** 批租状态机 */
function useBatchRentingMachine() {
  const machine = useMachine(machineBatchRenting, {
    actions: {
      setInitData: actions.assign((_ctx, ev) => {
        if (ev.type === 'START') {
          return {
            rentDur: ev.rentDur,
            rentDurUnit: ev.rentDurUnit,
            rentResValue: ev.rentResValue,
            addressList: ev.addressList,
            payAmount: ev.payAmount,
          }
        }
        return {}
      }),
    },
    services: {
      /** 提交订单 */
      submitOrder: async ({
        rentDur,
        rentDurUnit,
        rentResValue,
        addressList,
        payAmount,
      }) => {
        const receivers = addressList.join('\n')
        const res = await apis.apiOrderCreateBatchOrder(
          {
            requestBody: {
              receivers,
              rent_duration: rentDur,
              rent_time_unit: rentDurUnit,
              resource_value: rentResValue,
              pay_amount: payAmount,
              resource_type: 1,
            },
          },
        )
        return res
      },
    },
  })
  const states = reactiveComputed(() => {
    const _state = unref(machine.state)
    const idle = _state.matches('idle')
    const success = _state.matches('success')
    const requesting = _state.hasTag('requesting')

    return {
      idle,
      success,
      requesting,
    }
  })

  return { machine, states }
}

// todo 业务修改apikey不在是必须
const secretKey = useSecretKey()
const { machine, states } = useBatchRentingMachine()
const rentDur = useRentDuration()
const rentResValue = useRentResValue()
const receiveAddress = useReceiveAddress()
const { shortcutTime } = useRentalTime()
const { rentTimeSecond } = useRentalDuration()

function moreDurationChange(data: { dur: number; unit: 'h' | 'd' }) {
  rentDur.duration = data.dur
  rentDur.unit = data.unit
  rentForm.$form.value?.validate('rentDur', { triggerName: 'change' }).catch(() => {
  })
}

const rentForm = useAntFormValidator({
  // todo 业务修改不在需要填写key
  secretKey: {
    value: toRef(secretKey, 'key'),
  },
  rentResValue: {
    value: toRef(rentResValue, 'value'),
    validator(rule, value) {
      if (typeof value !== 'number')
        return Promise.reject($t('v9CtebgwTv2tjK6swSSJd'))

      if (typeof rentResValue.minValue !== 'number')
        return Promise.reject($t('2DtljKpN8ZqpcJuHqjfp'))

      if (value < rentResValue.minValue) {
        rentResValue.value = rentResValue.minValue
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(rentResValue.minValue + $t('gBxZSVwsjVNaeI6rUByHd'))
      }

      return Promise.resolve()
    },
  },
  receiveAddress: {
    value: toRef(receiveAddress, 'value'),
    validator(rule, value) {
      if (!value)
        return Promise.reject($t('zusWshvItgohoPIbWpV0K'))

      return Promise.resolve()
    },
  },
  rentDur: {
    value: toRef(rentDur, 'duration'),
    validator(rule, value) {
      if (typeof value !== 'number')
        return Promise.reject($t('ojnO7gOn5bBgUVpOzx3xy'))

      if (value % 1 !== 0)
        return Promise.reject($t('ly48hQBwarLWy6Bk9Qy8y'))

      if (rentDur.unit === 'd') {
        if (value < 1 || value > 30)
          return Promise.reject($t('OMQRqRZC4EdQNtRmoIOU5'))
      }
      if (rentDur.unit === 'h') {
        if (value !== 1 && value !== 3)
          return Promise.reject($t('OMQRqRZC4EdQNtRmoIOU5'))
      }

      return Promise.resolve()
    },
  },
  rentTimeSecond: {
    value: rentTimeSecond,
    validator(rule, value) {
      if (!value)
        return promiseError($t('dHgidzfd5qiruU6fTpsQ'))
      if (value <= 0)
        return promiseError($t('dHgidzfd5qiruU6fTpsQ'))
      return Promise.resolve()
    },
  },
})

const { target, checking } = useHideFormHelp(rentForm.$form)

const calculationData = computed(() => {
  /* 批量租赁购买能量 */
  let { originalPrice, amount } = useCalculation({
    resourceValue: rentForm.formState.rentResValue,
    rentDuration: rentDur.duration,
    rentTimeUnit: rentDur.unit,
  })
  originalPrice = originalPrice.multipliedBy(receiveAddress.addressList.length)
  amount = amount.multipliedBy(receiveAddress.addressList.length)
  return {
    originalPrice,
    amount,
  }
})

// 右边广告图
const batchRentingBg = useAssetOnLocale({
  zh: import('@/assets/imgs/batch-renting-zh-bg.png?url'),
  en: import('@/assets/imgs/batch-renting-en-bg.png?url'),
})

function toLogin() {
  globalModal.AuthModal?.openLogin?.(
    {
      loginSuccessCallback() {
      },
    },
  )
}

async function sumbit() {
  const formState = await rentForm.$form.value?.validate().catch(err => checking.setTrue())

  if (!formState)
    return

  // if (!checkAddress()) {
  //   aMessage.error($t('jk21xmAM1rFgR2Is9F88'))
  //   return
  // }

  machine.send({
    type: 'START',
    rentDur: rentDur.duration,
    rentDurUnit: rentDur.unit,
    rentResValue: formState.rentResValue,
    rentTimeSecond: formState.rentTimeSecond,
    addressList: [...receiveAddress.addressList],
    payAmount: toDecimalPlaces(unref(calculationData).amount, 6),
  })
}

// function checkAddress() {
//   const list = receiveAddress.addressList
//   return list.every(value => (tron.isAddress(value)))
// }

function useRentalDuration() {
  const rentTimeSecond = ref(3600)
  const chooseRentDuration = (item?: number) => {
    item = item ?? rentTimeSecond.value
    const data = shortcutTime.find((i: any) => i.value === item)
    if (!data)
      return
    rentTimeSecond.value = item as number
    rentDur.duration = data.dur
    rentDur.unit = data.unit
  }

  return {
    rentTimeSecond,
    chooseRentDuration,
  }
}

const { pause, resume, isActive } = useIntervalFn(() => {
  userInfoQuerier.refetch()
}, 5000)

onBeforeUnmount(() => {
  pause()
})

machine.service.onTransition((state) => {
})
</script>

<template>
  <div class="batch-renting">
    <div class="container gradient-border-box">
      <h1 class="title">
        <span>{{ $t('Mm9u-wekmnRkVZxfpX8gl') }}</span>
      </h1>
      <div class="main">
        <div class="form-info">
          <AForm
            class="antd-cover__bubble-help-form"
            v-bind="rentForm.formProps"
            layout="vertical"
            :disabled="states.requesting"
          >
            <!-- 租用能量数 top -->
            <AFormItem
              v-bind="rentForm.formItemProps.rentResValue"
              class="form-item"
            >
              <div class="label lt-tablet:!mb-0">
                <div>
                  <span>{{ $t('UjAljAAg7g8ekw_4Va47P') }}</span>
                  <span v-if="hasLogged" class="text-[#266ef1] text-18px mx-4 lt-tabletl:text-12px lt-tabletl:!hidden">
                    <span> {{ $t('zZDOHRyzqCjG0QnsGDU_9') }}: </span>
                    <span>{{ formatUserAmount(userInfo?.trx_money) }} TRX</span>
                  </span>
                </div>

                <div class="label-suffix lt-tabletl:!hidden">
                  <span> {{ $t('NSyq_yproNz1SBGGsBN8h') }}: </span>
                  <AvailablePopover
                    :title="$t('mlhc829WjrAXwBEZdSw')"
                    :columns="rentableColumns"
                    :dataSource="rentableData"
                  />
                </div>
              </div>
              <!-- 租用能量数 -->
              <div>
                <span v-if="hasLogged" class="text-18px lt-tabletl:text-12px tabletl:!hidden">
                  <span class="text-text-secondary"> {{ $t('zZDOHRyzqCjG0QnsGDU_9') }}: </span>
                  <span class="text-[#266ef1]">{{ formatUserAmount(userInfo?.trx_money) }} TRX</span>
                </span>

                <div class="lt-tabletl:text-12px tabletl:!hidden">
                  <span class="text-text-secondary"> {{ $t('NSyq_yproNz1SBGGsBN8h') }}: </span>
                  <AvailablePopover
                    :title="$t('mlhc829WjrAXwBEZdSw')"
                    :columns="rentableColumns"
                    :dataSource="rentableData"
                  />
                </div>
              </div>
              <div
                class="relative border-[none] rounded-[10px]"
              >
                <AInputNumber
                  v-bind="rentForm.formItemProps.rentResValue"
                  v-model:value="rentForm.formState.rentResValue"
                  class="w-full!"
                  :controls="false"
                  :min="unitPriceRange.minRentable"
                  :max="unitPriceRange.maxRentable"
                  :placeholder="`${$t('7SS3L5EGOJ9eM6dfwz-_C')} ${unitPriceRange.minRentable ?? 0} ~ ${unitPriceRange.maxRentable ?? 0} ${$t('gBxZSVwsjVNaeI6rUByHd')}`"
                  :disabled="states.requesting"
                />
                <i
                  v-if="!!rentForm.formState.rentResValue"
                  class="energy-input-clear i-ant-design:close-circle-outlined text-[14px] cursor-pointer"
                  data-test-id="agj7"
                  @click="rentForm.formState.rentResValue = null"
                />
              </div>
            </AFormItem>

            <!-- 租用时长  -->
            <AFormItem
              class="form-item mb-[10px]"
              v-bind="rentForm.formItemProps.rentDur"
            >
              <template #label>
                <span class="text-[18px] lt-tabletl:text-[16px]">{{ $t('c1R3GTGbhHGUQ-MFaNQpC') }}</span>
              </template>
              <MoreDuration
                v-model:rentDuration="rentForm.formState.rentDur"
                v-model:rentTimeSecond="rentForm.formState.rentTimeSecond"
                mode="batch-renting"
                @onChange="moreDurationChange"
              />
            </AFormItem>

            <!-- 接收能量的地址（一行一个） -->
            <AFormItem
              v-bind="rentForm.formItemProps.receiveAddress"
              class="form-item"
            >
              <div class="label lt-mobilel:w-[80%]">{{ $t('OVhHwL-i-tjdZ6onBAgbP') }}</div>
              <ATextarea
                v-model:value="rentForm.formState.receiveAddress"
                :autoSize="false"
                :rows="receiveAddress.maxCount + 1"
                class="textarea"
              />
              <div class="reacive-addr-info">
                {{ receiveAddress.count }} / {{ receiveAddress.maxCount }}
              </div>

              <span
                v-if="!!rentForm.formState.receiveAddress"
                class="textarea-clear"
                data-test-id="49v3"
                @click="rentForm.formState.receiveAddress = ''"
              >{{ $t('1g5xHP6ofivK-XiiUDOSN') }}</span>
            </AFormItem>

            <!-- 支付信息 -->
            <AFormItem v-if="rentForm.formState.rentResValue && rentForm.formState.rentDur && rentForm.formState.receiveAddress" class="form-item">
              <div class="label lt-mobilel:w-[80%]">{{ $t('QWNBNFCpECWKnkEbwzClt') }}</div>
              <div class="text-primary mt-[10px] bg-[#202C52] rounded-[10px] pt-[10px] pb-[8px] px-[10px]">
                <div>
                  <div class="flex-between mb-[6px]">
                    <span>{{ $t('MdLWMNeCJsOrXnrrrWLgX') }}：</span>
                    <p class="text-md">
                      {{ formatAmount(rentForm.formState.rentResValue, 0) }} {{ $t('A5FX7Ej5VR2oi-ftUBpb2') }},
                      {{ rentForm.formState.rentDur }}
                      <span v-if="rentForm.formState.rentDur === 1">
                        {{ rentDur.unit.replace('h', $t('SlBOLvhC-SbNV2HiN7_bi')).replace('d', $t('sgq3K4HMvzvjpCGigTbMu')) }}
                      </span>
                      <span v-else>{{ rentDur.unit.replace('h', $t('GRAxQVNTjLwXYfOpY6FRh')).replace('d', $t('8zE4c0R_g37KkcLgrn4u0')) }}</span>
                    </p>
                  </div>
                  <div class="flex justify-between items-baseline">
                    <span>{{ $t('eZ4sG6S0vn3U5mdh1etvj') }}：</span>
                    <span>
                      <!-- todo formatAmount 接受bignumber类型会有为什么问题吗？  -->
                      <span class="text-md line-through"> {{ formatAmount(calculationData.originalPrice, 6) }}  TRX</span>
                      <span class="text-text-green text-2xl ml2 font-bold lt-tabletl:text-15px"> {{ formatAmount(calculationData.amount, 6) }}  TRX </span>
                    </span>
                  </div>
                </div>
              </div>
            </AFormItem>

            <!-- 操作 -->
            <AFormItem class="operate">
              <!-- 登录 -->
              <AButton v-if="!hasLogged" class="submit comfirm-btn" data-test-id="ca5c" @click="toLogin">
                {{ $t('OoOWOdY5g6tHlgOHmJc11') }}
              </AButton>
              <!-- 开始请求 -->
              <AButton v-if="hasLogged" ref="target" :loading="states.requesting" class="submit comfirm-btn" data-test-id="ca6c" @click="sumbit">
                {{ $t('b4Yo6M5iiJxWzJTahYxm') }}
              </AButton>
            </AFormItem>
          </AForm>
        </div>
        <img class="batch-renting-bg" :src="batchRentingBg">
      </div>

      <AModal
        v-model:open="states.success"
        wrapClassName="ant-cover__basic-modal"
        :footer="null"
        destroyOnClose
        :maskClosable="false"
        :closable="false"
        @ok="machine.send('close')"
        @cancel="machine.send('close')"
      >
        <div class="flex justify-center items-center flex-col gap-[30px]">
          <i class="i-local:success text-[60px]" />
          <p>{{ $t('xiLdSpmPrPmgztXNhLiG') }}</p>
          <AButton class="comfirm-btn min-w-[160px]" @click="machine.send('close')">
            {{ $t('7sf5sL2XdyefcbfuW6666') }}
          </AButton>
        </div>
      </AModal>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.ant-input-number-input) {
  height: 50px;
  background: var(--bg-one);

  @media bp-lt-tabletl {
    position: relative;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
  }

  &::placeholder {
    color: red;
  }
}

:deep(.ant-input-number-focused) {
  box-shadow: none;
}

textarea {
  --uno: 'resize-none';
}

.batch-renting {
  --uno: 'w-100% h-100% p-40px';

  .container {
    --uno: 'border-none! max-w-1440px m-[0_auto] border-rd-30px';

    .main {
      --uno: 'flex px-40px';
    }

    .batch-renting-bg {
      --uno: 'mt-40px w-432px h-600px lt-laptop:hidden lt-tabletl:hidden';
    }
  }

  .title {
    --uno: 'text-28px px-40px py-28px';

    font-weight: bold;
    color: var(--first-text);

    span {
      position: relative;
      padding-left: 20px;

      &::before {
        position: absolute;
        top: 6px;
        left: 0;
        width: 4px;
        height: 26px;
        content: '';
        background: var(--highlight);
      }
    }
  }

  .form-info {
    --uno: 'mr-6 flex-1 lt-tabletl:mr-0';

    .result {
      --uno: 'text-[--first-text] text-[16px] h-40px';
    }

    .operate {
      :deep(.ant-form-item-control-input-content) {
        display: grid;
        grid-template-columns: 240px 1fr 1fr;
        gap: 10px 10px;
      }

      .toggle-continue-pause {
        --uno: ' w-210px h-54px py-10px px-30px text-20px text-[#8098bf]';
      }
    }
  }

  .form-item {
    --uno: 'mb-20px relative';

    .label {
      --uno: 'mb-3 text-18px flex text-[--first-text]';
    }

    .label-suffix {
      --uno: 'text-[18px] text-[#266ef1] flex flex-1 items-center justify-end';
    }

    .input-spceil {
      --uno: 'py-9px';
    }

    .input {
      --uno: 'w-100% !rounded-none !border-width-0 bg-[none] p-0 text-16px';

      color: var(--first-text);
      background: transparent;
      border: none;

      &::placeholder {
        --uno: 'text-[--secondary-text]';

        color: var(--secondary-text) !important;
      }

      &:focus {
        --uno: 'shadow-[none] !border-width-0';
      }
    }

    :deep(.secretkey) {
      .input {
        --uno: '!border-[none]';
      }
    }

    .key-input-clear {
      --uno: 'text-[#9a9a9a] top-10% translate-y-50% right-[-3px] absolute lt-mobilel:right-[10px]';
    }

    .energy-input-clear {
      --uno: 'text-[#9a9a9a] absolute right-[15px] translate-y--50%  top-50%';
    }

    .addon-after {
      --uno: 'absolute right-10px translate-y-50% top-40% text-[#9a9a9a] flex items-center h-30px ';
    }

    .icon {
      --uno: 'w-20px h-20px text-[#b0b0b0] ml-5px p-0 flex items-center justify-center border-[#b0b0b0] ';

      background: transparent;
      border-width: 1.5px;
    }

    .disabled {
      --uno: 'z-1';
    }

    .time-input-clear {
      --uno: 'm-[2px_10px_0_0]';
    }

    .textarea {
      --uno: 'h-300px px-15px py-8px border-rd-10px text-[--first-text] bg-[--bg-one] text-16px lh-20px';

      border: none;

      &::placeholder {
        --uno: 'text-[--secondary-text]';
      }
    }

    .textarea-clear {
      --uno: 'absolute right-15px bottom-15px text-[#266ef1] cursor-pointer';
    }

    .reacive-addr-info {
      --uno: 'absolute right-0 text-[--first-text]';
    }
  }

  .api-key {
    --uno: 'mb-20px lt-mobile:mb-11px';

    .label {
      --uno: 'text-18px';

      .text {
        --uno: 'text-[--first-text]';
      }
    }

    .apply {
      --uno: 'ml-10px text-bg-active lt-mobile:text-[16px]';
    }
  }

  .api-key-card {
    --uno: 'rounded my-4 mx-0 border-rd-10px lt-tabletl:my-11px';

    background: var(--bg-one);
    border-radius: 10px;

    & > * {
      --uno: 'py-12px px-15px';
    }

    .form-item {
      --uno: '!m-0';
    }

    .energy-count {
      --uno: 'text-bg-active text-[1.1em] flex pt-0';

      border-color: rgb(128 152 191 / 50%);

      .line {
        --uno: 'mx-20px my-0';
      }

      .rentable, .balance {
        --uno: 'flex items-center lt-tabletl:text-14px';
      }

      .value {
        --uno: 'ml-5px';
      }
    }
  }
}

@media bp-lt-tabletl {
  .batch-renting {
    --uno: 'pt-16px pb-20px px-16px';

    .container {
      --uno: 'border-rd-16px';

      background: linear-gradient(#1a2342, #1a2342) padding-box, linear-gradient(240deg, #00c1a5, #2a47ab) border-box;
      border: 3px solid transparent;
      border-radius: 16px;

      .main {
        --uno: 'px-16px';
      }
    }

    .title {
      --uno: 'text-26px px-0 py-16px text-center';

      border-radius: 16px 16px 0 0;

      span {
        --uno: 'p-0';

        &::before {
          display: none;
          content: '';
        }
      }
    }

    .form-info {
      .form-item {
        --uno: 'rounded-md mb-11px';

        .label {
          --uno: 'mb-11px text-16px';
        }

        .input {
          --uno: 'bg-inherit border-rd-10px text-16px focus:border-0 focus:shadow-inherit';
        }

        .input-spceil {
          --uno: 'w-80%';
        }

        .addon-after {
          --uno: 'top-25px right-5px';
        }

        .textarea {
          --uno: 'bg-inherit border-0 resize-none h-110px bg-[#13192e] py-9px px-12px text-14px ';
        }

        .textarea-clear {
          --uno: 'h-[fit-content] text-s right-5px top-0 py-2px px-10px text-14px';
        }

        .reacive-addr-info {
          --uno: 'bottom-[0] right-5px';
        }

        .label-suffix {
          --uno: ' text-12px';
        }

        .key-input-clear {
          --uno: 'text-[#9a9a9a] top-10% translate-y-50% right-[10px] absolute lt-mobilel:right-[10px]';
        }
      }

      .splice-item {
        --uno: 'bg-[#13192e] border-rd-10px px-12px';
      }

      .energy-count {
        --uno: 'px-12px';
      }

      .operate {
        --uno: 'mt-16px';

        :deep(.ant-form-item-control-input-content) {
          display: grid;
          grid-template-columns: auto;
          gap: 10px 10px;
        }

        .submit {
          --uno: '!w-100% text-18px';
        }

        .toggle-continue-pause {
          --uno: '!w-100% text-18px';
        }
      }

      .result {
        --uno: 'text-16px';
      }
    }
  }
}
</style>
