import { createMachine } from 'xstate'

export { machine }
export type { IContext }

interface IContext {
  rentResValue: number
  rentDur: number
  rentDurUnit: string
  receiveAddress: string
  addressList: string[]
  payAmount: number
}

const machine
  /** @xstate-layout N4IgpgJg5mDOIC5QCECGAXAxgCwEpgDt0BLAqAOmIgBswBiAZQBUBBXJgbQAYBdRUAA4B7WMRJCC-EAA9EAVgBMAGhABPRAA4AjOQAsAdgCcWgGwBmQ9t0muugL52VaLHkIky5AE5gAjgFc4dEoaem4+JBBhUXFJCNkEBTkdA2N9OUMTIxtDXRV1BEN9DXJDRKLdBWNKrg0HJwwcfCJSCm9-QODaOg4tcMERMWIJKXjE5KMtNIysrhy8xF0uBXINdLMTDaLDMzMFfTqQZ0a3Fq9fANggqi6OBT7IgZiRxC1E3RKudbNtU1KNfXmCEUOjMujktjSGkK20MByOrmaHjaFyCQk8EDAngAtLA-AAjAC2gzIdAgEjAlAIADchABrCm4wliLFojGeMJSKKDYZxRD6XQ6LRydZgrRQ0pGQGWLjkEwadafXYmLQ7WqOQ4NBHuVrnDqszE4-FE7V0TGeNHkATUDAAMzRBPIjONLPRmI5ES5T15CSSegmU0yGVmuTUiG24y4XBswtKcjjcM1TW1Z3al3I+uxTuJUEYTAA8gAFd39aJDWKgeLC-TkBQ1Ha6QxyTIGEyA1ZycivLSzJZaUxNhMuJOnZF612Zo3ZugFlgAVQYAFFiw9SzyK-J1iUFPKoVGoVpSoCpuQuHJVoZPvpNgozIPjoidamggJUH5YJA6ABhPMAOSYAEkf1nJdeE5R4y2eBJCnIfRKkmV4FBMAVMkBMwoxgjINA2DRdAsBsTDvLUR11NMXzfD9mELZdPQg71Xjkd4Ly+H4TD+AFQwQCwO0+UxMiFBQD1jQjhyREjUXHLF2k8VQWlJclKRpelyCk1QXTZajwLXGQ+QME9jC0CoTAEqE0kBcxDBWNDYNWSMTAY-Z1XhETHxRdMJJU2SzQtK1bXtZSAmktS3VAj1NPLbSCiw2VA30FUzCbK8Q3yC8TBrPZ5VgjI5X0Bz6iHE5RKfNy2UkgKZJJSiixCktuXC+JuwUZZZj7b5YpvSE227Ts7J2fllX0WxhIKlyxxKjySRnecQPuGitPq3sVj7A9twUMEDGUDjYveBqMqMzJa1vA4CCEDF4AiJzhrA1c6sQLFDEBLECMcxNhs6MArtqyCDL2brY0seDrNQywTzFcxEg0LCKkOvL72TUdLg+r1104+6OIEiyzEmWK+xbGpVqGh8U1c653tC67IP+KVlRrHKGNKLGjNyjV8sJ+HxJKrNtUR2jkdwiyNESYVcKKfk0jMKUm07eV0kjaxzKemGiMK1yyPfCBubmhZgdpyZtkQ3R-iSxAuPIJIsN2M9IZvAm4bE4qDXGqANZugo0JWAUDKhcxI3FjjG2KOV4uVWx-katUHCAA */
  = createMachine(
    {
      context: {
        rentDur: 0,
        nextState: 'continue',
        addressList: [],
        rentDurUnit: '',
        rentResValue: 0,
        payAmount: 0,
      },
      id: 'BatchRenting',
      initial: 'idle',
      states: {
        idle: {
          on: {
            START: {
              target: 'request',
              actions: {
                type: 'setInitData',
              },
            },
          },
        },
        request: {
          initial: 'idle',
          states: {
            'idle': {
              always: {
                target: 'order-submiting',
              },
            },
            'order-submiting': {
              invoke: {
                id: 'submit-order',
                onDone: {
                  target: '#BatchRenting.success',
                },
                onError: {
                  target: '#BatchRenting.idle',
                },
                src: 'submitOrder',
              },
              tags: 'requesting',
            },
          },
        },
        success: {
          on: {
            close: {
              target: 'idle',
            },
          },
        },
      },
    },
    {},
  )
